// dldbfrontend special css

#content-footer .version {
    position:absolute;
    right: 24px;
    top: 3em;
}

#map {z-index: 1;}
.flyin-overlay .fio-container__body {
    max-height: 95%;
}


#bmi115WidgetRoot #bmi115ModalHeader {
    color: var(--color-black) !important;
    -webkit-text-fill-color: var(--color-black) !important;
}
#bmi115WidgetRoot form div.e1rptwls3 {
    background: var(--color-grey) !important;
    color: var(--color-black) !important;
    -webkit-text-fill-color: var(--color-black) !important;
}
#bmi115WidgetRoot form .buttonPrevStep,
#bmi115WidgetRoot form .buttonNextStep {
    background: #001c3e !important;
    color: var(--color-white)!important;
    -webkit-text-fill-color: var(--color-white) !important;
}

#bmi115WidgetRoot .css-v74y11::before,
#bmi115WidgetRoot .css-v74y11::after,
#bmi115WidgetRoot .css-3uc6d1::before,
#bmi115WidgetRoot .css-3uc6d1::after {
    background: var(--color-black)  !important;
    border: 2px solid var(--color-black)  !important;
}
#bmi115WidgetRoot form .css-16u6py0.e5prln80::before,
#bmi115WidgetRoot form .css-16u6py0.e5prln80::after,
#bmi115WidgetRoot .css-1jcbmf8::before,
#bmi115WidgetRoot .css-1jcbmf8::after {
    background: var(--color-white)  !important;
    border: 2px solid var(--color-black)  !important;
}

#bmi115WidgetRoot form .buttonPrevStep:active, 
#bmi115WidgetRoot form .buttonPrevStep:focus,
#bmi115WidgetRoot form .buttonNextStep:active, 
#bmi115WidgetRoot form .buttonNextStep:focus
{
    box-shadow: none;
    background: #003371 !important;
}
#bmi115WidgetRoot form .buttonPrevStep:hover, 
#bmi115WidgetRoot form .buttonNextStep:hover
{
    box-shadow: none;
}

ul.list--functionlist .listitem.appointment-all {
    background-color: #fff;
    border: none;
}
 
ul.list-tablelist li>a, ul.list-clean li>a {
    font-weight: bold;
}

.modul-stickybutton button.button-submit {
    margin: 0; 
}


.modul-stickybutton.permanent-sticky span,
.modul-stickybutton.permanent-sticky .button-submit {
    display: block;
}

.modul-stickybutton.permanent-sticky .button-submit.hide,
.modul-stickybutton.permanent-sticky span.hide {
    display: none;
}


.emergency-box {
    padding: 1em !important;
}


$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";
@import 'font.scss';